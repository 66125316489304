import React, { useState, useEffect } from "react"
import "./index.css"
import { Link } from "gatsby"
// import "aos/dist/aos.css"
import FirebaseApp from "./../../config/firebase"
import { Container, Row, Col, Form, Spinner } from "react-bootstrap"

import { AiFillCheckCircle } from "react-icons/ai"
import { navigate } from "gatsby" //import navigate from gatsby
let database = FirebaseApp.database().ref("/")

export default function IITSignup(props) {
  var [name, setName] = useState("")
  var [fatherName, setFather] = useState("")
  var [email, setEmail] = useState("")
  var [password, setpassword] = useState("")
  var [nicNumber, setNic] = useState("")
  var [mobileNum, setMobileNum] = useState("")
  var [course, setcourse] = useState("")
  var [qualification, setqualification] = useState("")
  var [MobileAppCretificate, setMobileAppCretificate] = useState("")
  var [GrapichCretificate, setGrapichCretificate] = useState("")
  var [url, seturl] = useState("")
  var [err, seterr] = useState("")
  var [disabled, setdisabled] = useState(false)
  var [checked, setChecked] = useState(false)
  const [isLoader, setIsLoader] = useState(false)
  const [systemToken, setSystemToken] = useState("")
  const [isAdmission, setAdmition] = useState(true)
  const [OnetoOneStudendts, setOnetoOneStudendts] = useState([])
  const [eCommerceStudents, setECommerceStudents] = useState([])
  const [digitalMarketingStudents, setDigitalMarketingStudents] = useState([])
  const [wordpressStudents, setWordpressStudents] = useState([])

  useEffect(() => {
    var oneToOneStudents = []
    database.child("Admission").once("value", res => {
      let data = res.val()
      setAdmition(data.admission)
    })
    database.child("Registration/").on("child_added", res => {
      var IndvidualStudendts = res.val()
      if (IndvidualStudendts.courseType === "1/1 Course") {
        console.log(IndvidualStudendts.courseType)
        oneToOneStudents.push(IndvidualStudendts)
        setOnetoOneStudendts(oneToOneStudents)
        if (IndvidualStudendts.course === "E-commerce Course") {
          eCommerceStudents.push(IndvidualStudendts)
          setECommerceStudents(eCommerceStudents)
        } else if (IndvidualStudendts.course === "Digital Marketing") {
          digitalMarketingStudents.push(IndvidualStudendts)
          setDigitalMarketingStudents(digitalMarketingStudents)
        } else if (IndvidualStudendts.course === "Wordpress") {
          wordpressStudents.push(IndvidualStudendts)
          setWordpressStudents(wordpressStudents)
        }
      } else {
        console.log(IndvidualStudendts.courseType)
      }
      // let
    })
  }, [])

  const getImageURL = async (e, value) => {
    if (e.target.files.length !== 0) {
      let imageName = e.target.files[0].name
      var imageType = e.target.files[0].type
      let ref = FirebaseApp.storage()
        .ref("/")
        .child(`studentsImages/${imageName}`)
      if (imageType === "image/png" || imageType === "image/jpeg") {
        setdisabled(true)
        seterr("")
        await ref.put(e.target.files[0])
        ref.getDownloadURL().then(url => {
          if (value === "profile") {
            seturl(url)
            setdisabled(false)
          }
        })
      } else {
        seterr("Please select file in jpeg or png format")
      }
    } else {
      setdisabled(false)
      seterr("")
    }
  }

  const _applyNow = async () => {
    console.log("All Students", OnetoOneStudendts.length)
    console.log("E-Commerce Students", eCommerceStudents.length)
    console.log("Digital Marketing Students", digitalMarketingStudents.length)
    console.log("Wordpress", wordpressStudents.length)
    if (name === "") {
      seterr("Please Enter your name")
      setIsLoader(false)
    } else if (fatherName === "") {
      seterr("Please enter your father's name")
      setIsLoader(false)
    } else if (email === "") {
      seterr("Please enter your email")
      setIsLoader(false)
    } else if (password === "") {
      seterr("Please enter your password")
      setIsLoader(false)
    } else if (nicNumber === "") {
      seterr("Please enter your CNIC number")
      setIsLoader(false)
    } else if (nicNumber.length < 13) {
      seterr("Please 14 character in nic number")
      setIsLoader(false)
    } else if (mobileNum === "") {
      seterr("Please enter your mobile number")
      setIsLoader(false)
    } else if (course === "" || course === "Select Courses") {
      seterr("Please select any one course")
      setIsLoader(false)
    }

    // else if (course === "Mobile App Development" && MobileAppCretificate === "") {
    //   seterr("Required web developement certification for apply in this course")
    //   setIsLoader(false);
    // }

    // else if (course === "Video Editing" && GrapichCretificate === "") {
    //   seterr("Required Graphic design cretificate for apply in this course")
    //   setIsLoader(false);
    // }
    else if (mobileNum.length < 11) {
      seterr("Please 11 character in mobile number")
      setIsLoader(false)
    } else if (
      qualification === "" ||
      qualification === "Select Qualification"
    ) {
      seterr("Please select your qualification")
      setIsLoader(false)
    } else if (url === "") {
      seterr("Please upload your passport size photo")
      setIsLoader(false)
    } else if (checked === false) {
      seterr("You must have to select checkbox")
      setIsLoader(false)
    } else {
      var obj = {
        name: name,
        fatherName: fatherName,
        email: email,
        nicNumber: nicNumber,
        qualification: qualification,
        url: url,
        approved: false,
        mobileNum: mobileNum,
        course,
        systemToken,
      }
      // if (GrapichCretificate !== "") {
      //   obj.certificate = GrapichCretificate
      // }
      // else if (MobileAppCretificate !== "") {
      //   obj.certificate = MobileAppCretificate
      // }
      // if (course === "Freelancing") {
      //   obj.oneTimeFees = true;
      //   obj.totalFees = 15000;
      //   obj.addmissionFees = 15000;
      //   obj.duration = false
      // }
      // else if (course === "Web Development & Web Designing") {
      //   obj.oneTimeFees = false;
      //   obj.totalFees = 23000;
      //   obj.addmissionFees = 5000;
      //   obj.monthlyFees = 3000;
      //   obj.duration = [
      //     {
      //       month: "First Month",
      //       paid: true
      //     },
      //     {
      //       month: "Second Month",
      //       paid: false
      //     },
      //     {
      //       month: "Third Month",
      //       paid: false
      //     },
      //     {
      //       month: "Fourth Month",
      //       paid: false
      //     },
      //     {
      //       month: "Fifth Month",
      //       paid: false
      //     },
      //     {
      //       month: "Sixth Month",
      //       paid: false
      //     }
      //   ]
      // }
      // else {
      obj.oneTimeFees = true
      obj.totalFees = 15000
      obj.addmissionFees = 5000
      obj.monthlyFees = 3000
      obj.duration = false
      obj.courseType = "1/1 Course"
      // }
      setIsLoader(true)
      await FirebaseApp.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async res => {
          obj.uid = res.user.uid
          database.child("Registration/" + `${res.user.uid}` + "/").set(obj)
          localStorage.setItem("user", JSON.stringify(obj))
          seterr("")
          setName("")
          setFather("")
          setEmail("")
          seterr("")
          setNic("")
          seturl("")
          setqualification("")
          await FirebaseApp.auth()
            .currentUser.sendEmailVerification()
            .then(() => {
              navigate("/emailVerification") //navigate to edit page
            })
            .catch(err => {
              seterr(err.message)
              setIsLoader(false)
            })
        })
        .catch(err => {
          seterr(err.message)
          setIsLoader(false)
        })
    }
  }
  // SET VALID MOIBLE NUMBER
  const setNumber = v => {
    if (v >= 0 && v.length <= 11) {
      setMobileNum(v)
      seterr("")
    }
  }
  const setCnic = v => {
    if (v >= 0 && v.length <= 13) {
      setNic(v)
      seterr("")
    }
  }

  return (
    <div
      style={{
        backgroundColor: "#fae6ff",
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="Signup">
              <p className="_formTitle">Student Sign Up</p>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/admission-portal-11282.appspot.com/o/colorlogo-01.png?alt=media&token=27dd4249-7e3e-4a78-a1f7-814cb689d254"
                className="logo"
              />
              {OnetoOneStudendts.length === 6 ? (
                <div>
                  The individual seates are filled you can apply in next batch
                </div>
              ) : (
                <>
                 
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      placeholder="Name"
                      onChange={e => {
                        seterr("")
                        setName(e.target.value)
                      }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      value={fatherName}
                      placeholder="Father Name"
                      onChange={e => {
                        seterr("")
                        setFather(e.target.value)
                      }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="text"
                      className="_input"
                      placeholder="Email"
                      onChange={e => {
                        seterr("")
                        setEmail(e.target.value)
                      }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="password"
                      className="_input"
                      placeholder="Password"
                      onChange={e => {
                        seterr("")
                        setpassword(e.target.value)
                      }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="number"
                      className="_input"
                      value={nicNumber}
                      placeholder="CNIC Number"
                      onChange={e => {
                        setCnic(e.target.value)
                        seterr("")
                      }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="file"
                      className="_input"
                      onChange={getImageURL}
                      style={{ border: "2px solid green" }}
                    />
                  </div>
                  <div className="_inputDiv">
                    <input
                      type="number"
                      className="_input"
                      value={mobileNum}
                      placeholder="Mobile Number"
                      onChange={e => {
                        seterr("")
                        setNumber(e.target.value)
                      }}
                    />
                  </div>
                  <select
                    className="_select_Course"
                    value={course}
                    onChange={e => {
                      setcourse(e.target.value)
                      seterr("")
                    }}
                  >
                    <option selected style={{ color: "grey" }}>
                      Select Courses
                    </option>
                    {eCommerceStudents.length === 2 ? null : (
                      <option>E-commerce Course</option>
                    )}
                    {digitalMarketingStudents.length === 2 ? null : (
                      <option>Digital Marketing</option>
                    )}
                    {wordpressStudents.length === 2 ? null : (
                      <option>Wordpress</option>
                    )}
                  </select>
                  {course === "Mobile App Development" ? (
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile1"
                        label={
                          MobileAppCretificate === "" ? (
                            "upload your web development certificate"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded certification successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "Mobile App Development")}
                      />
                    </Form.Group>
                  ) : null}

                  {course === "Video Editing" ? (
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile2"
                        label={
                          GrapichCretificate === "" ? (
                            "upload your graphic designing certificate"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded certification successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "Video Editing")}
                      />
                    </Form.Group>
                  ) : null}

                  <select
                    className="_select_Course"
                    value={qualification}
                    onChange={e => {
                      setqualification(e.target.value)
                      seterr("")
                    }}
                  >
                    <option selected style={{ color: "grey" }}>
                      Select Qualification
                    </option>
                    <option>Matriculation</option>
                    <option>Intermediate</option>
                    <option>Graduate</option>
                  </select>
                  <div className="_inputDiv">
                    <Form.Group>
                      <Form.File
                        id="exampleFormControlFile3"
                        label={
                          url === "" ? (
                            "Upload Photo"
                          ) : (
                            <div className="_upload_pic_css">
                              {" "}
                              <div>uploaded successfullly </div>
                              <AiFillCheckCircle />{" "}
                            </div>
                          )
                        }
                        className="_upload"
                        onChange={e => getImageURL(e, "profile")}
                      />
                    </Form.Group>
                  </div>

                  {url !== "" ? (
                    <div className="_image_caption" style={{ color: "green" }}>
                      uploaded pic successfully
                    </div>
                  ) : (
                    <div className="_image_caption">
                      Upload your passport size photo with blue background
                    </div>
                  )}
                  <div
                    style={{ textAlign: "left", width: "80%", margin: "auto" }}
                  >
                    <input
                      type="checkbox"
                      onChange={e => setChecked(e.target.checked)}
                    />
                    <span className="_laptopTxt">
                      I have laptop for classes
                    </span>
                  </div>
                  <div className="_err">{err}</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      justifyContent: "space-around",
                    }}
                  >
                    <div className="_text"> Already a member?</div>
                    <div className="_text">
                      <Link to="/">Sign In</Link>
                    </div>
                  </div>

                  {isAdmission ? (
                    <button
                      className="_btn"
                      onClick={_applyNow}
                      disabled={disabled}
                      style={disabled ? { opacity: 0.5 } : { opacity: 1 }}
                    >
                      {isLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Sign Up"
                      )}
                    </button>
                  ) : (
                    <button
                      className="_btn"
                      disabled
                      style={{ opacity: 0.5, width: "90%", fontSize: 10 }}
                    >
                      {isLoader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Admission closed! Wait for next Batch"
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
